import { Location } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RedirectCommand, Router, RouterStateSnapshot } from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AccountInfo, AuthenticationResult, BrowserAuthError, EventError, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { BehaviorSubject, Observable, filter, lastValueFrom, switchMap, take } from 'rxjs';
import { PortalCommunicationService } from '../../portal/services/portal-communication.service';
import { PortalStore } from '../../portal/services/portal.store';
import { ApplicationType } from '../../shared/models/application-type';
import { TenantKey, getTenantById } from '../../shared/models/tenant';
import { IamCommunicationService } from './iam-communication.service';
import { IamConfigService } from './iam-configuration.service';
import { IamStore } from './iam.store';
import { PrivilegedGroupService } from '../features/privileged-group/api/privileged-group.service';

@Injectable({ providedIn: 'root' })
export class IamAuthService implements OnDestroy{

  public service!: MsalService;
  public configuration: IamConfigService = new IamConfigService();

  public inited$ = new BehaviorSubject<boolean>(false);

  private broadcast!: MsalBroadcastService;
  
  constructor(
    public store: IamStore,
    public communication: IamCommunicationService,
    private router: Router,
    private location: Location,
    private portalStore: PortalStore,
    private portalCommunication: PortalCommunicationService,
    private privilegedGroupService: PrivilegedGroupService
  ) {
    //this.layout.startLoading();
    const tenant = this.portalStore.current.tenant;
    if (tenant) {
      try {
        this.store.setTenant(this.portalStore.current.tenant);
        console.log("IamAuthService.constructor: set tenant from portal store", this.store.current.tenant);
        this.initForTenant(this.store.current.tenant!);
      } catch (error) {
        console.log("IamAuthService.constructor: error setting tenant from portal store", error);
        this.login();
      }
    }

    this.communication.userLogin$.subscribe(() => {
      this.store.cleanUser();
      console.log('IamAuthService: iamCommunication.onUserLogin: logging in IAM');
      this.login();
    })

    //logout also when portal user logs out
    this.communication.userLogout$.subscribe(() => {
      console.log('IamAuthService: iamCommunication.onUserLogout: logging out IAM');
      this.logout();
    })

    this.communication.userLoginCompleted$
      .pipe(filter((f) => f != null), take(1))
      .subscribe(() => {//u
        //this.layout.stopLoading();

        this.privilegedGroupService.setBaseUrl(this.store.apiUrl);

        //this.configurationService.permissions();
          //.subscribe((prm) => this.store.setPermissions(prm));
        //this.userService.userMeGet();
          //.subscribe((user) => {
          //  this.store.setIamUser(user);
          //});
      });
  }

  findTokenPageUrl(redirect?:string): string { return `/iam/login?redirect=${redirect ?? window.location.pathname}`; }

  async initForTenant(tenant: TenantKey) {

    console.log('IamAuthService: initForTenant', tenant);

    this.configuration.setTenant(tenant);
    this.portalStore.setTenant(tenant);
    this.store.setTenant(tenant);

    this.service = new MsalService(new PublicClientApplication(this.configuration.getMsalConfig()), this.location);

    this.broadcast = new MsalBroadcastService(this.service.instance, this.service);

    this.initBroadcast();

    await lastValueFrom(this.service.handleRedirectObservable());
    //this.service.handleRedirectObservable().subscribe();

    console.log('IamAuthService: initForTenant completed');
  }

  getAuthToken(): Observable<AuthenticationResult> {
    return this.service.acquireTokenSilent({
      scopes: this.configuration.getMsalScopes()
    });
  }

  ngOnDestroy(): void {
    // Clean up the storage event listener when the service is destroyed
    //window.removeEventListener('storage', this.storageListener);
  }

  private async login(): Promise<void> {
    const activeAccount = this.portalStore.current.user;
    if (activeAccount) {
      await this.loginWithPortalAccount(activeAccount);
    } else {
        this.portalCommunication.userLogin$.next(ApplicationType.Iam);
      this.portalCommunication.userLoginCompleted$
        .pipe(filter(f => f != null), take(1))
        .subscribe(async (r) => {
          if (r as AccountInfo) {
            await this.loginWithPortalAccount(this.portalStore.current.user!);
          }
        });
    }
  }

  private loginWithPortalAccount(account: AccountInfo): Promise<void|AuthenticationResult> {
    return this.service.instance.ssoSilent({
      account: account,
      scopes: this.configuration.getMsalScopes()
    }).then(response => {
      // Successfully acquired token silently for App2
      console.log('IamAuthService: ssoSilent completed');
      return response;
    }, error => {
      console.error("IamAuthService: ssoSilent failed", error);
      // Handle failure, possibly by falling back to popup or redirect
      //this.portalCommunication.userLogin$.next(ApplicationType.Iam);
      //redirect to 
      this.router.navigateByUrl(this.router.parseUrl(this.findTokenPageUrl(this.router.url)), { skipLocationChange: true })
    });
  }

  private logout(): Observable<void> {
    this.store.cleanUser();
    return this.service.logoutRedirect({
      onRedirectNavigate: () => {
        // Return false to stop navigation after local logout
        return false;
      }
    });
  }

  private initBroadcast() {
    //const events: EventType[] = [EventType.INITIALIZE_END, EventType.LOGIN_SUCCESS, EventType.LOGIN_FAILURE, EventType.LOGOUT_END];
    this.broadcast.msalSubject$
      .pipe(
        //filter((msg: EventMessage) => events.includes(msg.eventType)),
      )
      .subscribe(async (msg: EventMessage) => {
        console.log(msg.eventType);

        switch (msg.eventType) {
          case EventType.INITIALIZE_END:
            this.inited$.next(true);
            console.log('IamAuthService.broadcast: IamAuth inited');
            break;
          case EventType.LOGIN_SUCCESS:
            console.log('IamAuthService.broadcast: Login success', msg.payload);
            this.setActiveAccount((msg.payload as AuthenticationResult).account);
            break;
          case EventType.LOGIN_FAILURE:
            console.error('IamAuthService.broadcast: Login failed', msg);
            if (this.location.path().indexOf('/login') == -1) {
              this.loginFailedRedirect(msg.error);
            }
            break;
          case EventType.ACQUIRE_TOKEN_SUCCESS:
            //console.log('IamAuthService.broadcast: Acquire token success', msg.payload);
            this.setActiveAccount((msg.payload as AuthenticationResult).account);
            break;
          case EventType.ACQUIRE_TOKEN_FAILURE:
            console.log('IamAuthService.broadcast: Acquire token failed', msg.error);
            //handle error
            var authError = msg.error as BrowserAuthError;
            if (authError && authError.errorCode == 'login_required') {
              //login request
              this.communication.userLogin$.next(ApplicationType.Iam);
            }
            break;

          case EventType.LOGOUT_END:
            console.log('IamAuthService.broadcast: Logout completed');
            this.communication.userLogoutCompleted$.next();
        }
      });
  }

  private setActiveAccount(activeAccount: AccountInfo) {
    let currentActiveAccountId = this.service.instance.getActiveAccount()?.idToken;

    if (currentActiveAccountId && currentActiveAccountId == activeAccount.idToken) {
      if (this.store.current.user?.idToken != currentActiveAccountId) {
        this.store.setUser(activeAccount);
        this.portalStore.setUser(activeAccount);
        console.log("IamAuthService.broadcast: user set active account")
      }
      return;
    }

    this.service.instance.setActiveAccount(activeAccount);

    this.store.setUser(activeAccount);
    this.portalStore.setUser(activeAccount);
    let tenant = getTenantById(activeAccount?.tenantId ?? '');
    if (tenant && !this.store.current.tenant) {
      this.store.setTenant(tenant);
      //if (!this.portalStore.current.tenant) {
        this.portalStore.setTenant(tenant);
      //}
    }

    this.communication.userLoginCompleted$.next(activeAccount);

    console.log("IamAuthService.broadcast: user set active account")
  }

  private loginFailedRedirect(error?: EventError) {
    console.log('IamAuthService: redirect to login failed page', error);
    if (error) {
      this.communication.userLoginCompleted$.next(error);
    }
    this.router.navigate(['/error/login-failed']);
  }
}

export const IamGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {

  console.log('IamGuard: start can activate');
  const router = inject(Router);
  const store = inject(IamStore);
  try {
    const auth = inject(IamAuthService);
    if (!store.current.tenant || !store.current.user) {
      console.log('IamGuard: redirect to find token');
      return new RedirectCommand(router.parseUrl(auth.findTokenPageUrl(state.url)), { skipLocationChange: true });
    }
    return new Promise((resolve, reject) => {
      auth.inited$
        .pipe(filter(f => f), take(1))
        .subscribe((inited) => {
          if (inited) {
            console.log('IamGuard: auth inited');
            if (!store.current.user) {
              console.log('IamGuard: request login');
              auth.communication.userLogin$.next(ApplicationType.Iam);
              auth.communication.userLoginCompleted$
                .pipe(filter(f => f != null), take(1))
                .subscribe(() => {
                  console.log('IamGuard: user login completed');
                  resolve(true);
                });
            }
            console.log('IamGuard: resolved');
            resolve(inited);
          }
        });
    })
  } catch (error) {
    store.setTenant(undefined);
    return new RedirectCommand(router.parseUrl('/iam/login'), { skipLocationChange: true });
  }
};


@Injectable()
export class IamInterceptor implements HttpInterceptor {

  constructor(private authService: IamAuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //TODO: add error handling
    const isProtectedResource = req.url.startsWith(this.authService.configuration.apiUrl) || false;
    if (isProtectedResource) {
      return this.authService.getAuthToken().pipe(
        switchMap((result: AuthenticationResult) => {

          const headers = req.headers.set(
            "Authorization",
            `Bearer ${result.accessToken}`
          );

          const requestClone = req.clone({ headers });

          return next.handle(requestClone);
        })
      );
    } else {
      // If not protected, forward the original request
      return next.handle(req);
    }
  }
}

